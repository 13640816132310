import React from 'react'
import logo from '../assets/logo.png'
import { GrInstagram } from "react-icons/gr";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhoneSquare } from "react-icons/fa";


export default function Footer() {
  return (
    <div className="footer">
      <div className="branding">
                <img src={logo} alt="logo" />
                <p>Smart Hydration.</p>
      </div>
      <div></div>
      
      <div></div>
      <div className="social">
        <a href="https://instagram.com/usura.xyz" className="social-icons"><GrInstagram className="social-insta" /></a>
        <a href="mailto:carterjohnscherer@gmail.com?subject=Customer%20Inquiry&body=Howdy,%20HydroLog!" className="social-icons"><HiOutlineMail className="social-mail" /></a>
        <a href="tel:+16502799135" className="social-icons"><FaPhoneSquare className="social-phone"/></a>

      </div>
    </div>
  )
}
