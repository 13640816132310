import React from 'react';
import "./scss/index.scss";
import NavBar from "./components/Navbar";
import Home from "./components/Home";
import Steps from "./components/Steps";
import Design from "./components/Design";
import Footer from "./components/Footer";
import MastHead from "./components/MastHead";
import Product from "./components/Product";
import Magsafe from "./components/Magsafe";
import Testflight from "./components/Testflight";

export default function App() {
  return (
    <div className="app-container">
      <NavBar />
      <Home />
      <Steps />
      <Magsafe />
      <Design />
      <Testflight />
      <Product />
      <Footer />
      <MastHead />
    </div>
  )
}
