import React from 'react'
import magsafePhoto from "../assets/magsafe.png"


export default function Magsafe() {
  return (
    <div className="magsafe">
    <div className="magsafe-container">
        <div><h1 className="magsafe-content" >Wireless charging + MagSafe</h1></div>
       <div className="magsafe-img"><img src={ magsafePhoto } alt="Hydrolog Cap" /></div> 
    </div>
    </div>
  )
}
