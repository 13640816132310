import React from 'react'
import Hydro1 from "../assets/HeaderRender.png"
import HydroMobile from "../assets/mobileHeaderProduct.png"

export default function Home() {
  return (
    <div className="home">
        <div className="container">
            <div className="image-container">
                
                    <img src={ Hydro1 } className="desktop-photo" alt="Hydrolog Cap" />
                    <img src={ HydroMobile } className="mobile-photo" alt="Hydrolog Cap" />
                
            </div>
            <div className="content">
                <p className="title">You are dehydrated.</p>
                <p className="description">Learn your body’s demands without switching bottles.</p>
                <div className="button-box">
                    
                </div>
            </div>
            
        </div>
    </div>
  )
}
