import React from 'react'
import testflightapp from "../assets/testflightapp.png"

export default function Testflight() {
  return (
    <div className="testflight">
      <div className="testflight-container">
        <div className="testflight-button-box">
          <p className="testflight-content">Download TestFlight on the AppStore and check your email for an invitation code once you placed an order.</p>
          <h1 className="testflight-header">Having trouble?</h1> {/* Added h1 */}
          <a href="tel:+16502799135">
            <button className="button">Call</button>
          </a>
        </div>
        <div className="testflight-img">
          <img src={testflightapp} alt="Hydrolog Cap" />
        </div>
      </div>
    </div>
  )
}
