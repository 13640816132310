import React, { useState } from 'react';
import ProductOriginal from "../assets/ProductOriginal.png"
import ProductClear from "../assets/ProductClear.png"
import ProductWhite from "../assets/ProductWhite.png"
import ProductBlue from "../assets/ProductBlue.png"
import ProductPink from "../assets/ProductPink.png"
import ProductGreen from "../assets/ProductGreen.png"

import logoSimpleBlack from '../assets/logoSimpleBlack.png'
import { FaTrademark } from "react-icons/fa";

export default function Product() {
  
  const [selectedColor, setSelectedColor] = useState(null);

  const [selectedBottle, setSelectedBottle] = useState('');


  // List of color boxes with names and links
  const colors = [
    { name: 'Original', color: '#1a1a1a', link: 'https://buy.stripe.com/cN2bJpdGL9nG5SEfZ5', image: ProductOriginal },
    { name: 'Clear', color: '#8a8a8a4a', link: 'https://buy.stripe.com/5kA00HfOT8jCbcY7sD', image: ProductClear },
    { name: 'Pure', color: '#E3E5E3', link: 'https://buy.stripe.com/dR628PfOTdDW4OA5ks', image: ProductWhite },
    { name: 'Droplet', color: '#494CB0', link: 'https://buy.stripe.com/00g00H5af9nGdl614d', image: ProductBlue },
    { name: 'Bubble', color: '#F0BFDB', link: 'https://buy.stripe.com/eVa00HbyDeI03Kw9AK', image: ProductPink },
    { name: 'Plankton', color: '#F6FBD4', link: 'https://buy.stripe.com/eVa9Bh9qv6bu0yk8wI', image: ProductGreen },
  ];

  const handleSelectColor = (color) => {
    setSelectedColor(color);
    
  };

  const handleSelect = (e) => {
    setSelectedBottle(e.target.value);
  };

  return (
    <div className="product">
        
        <div className="product-photo">
          <img src={selectedColor ? selectedColor.image : ProductOriginal} alt="Hydrolog Cap" />
        </div>

      <div className="payment-info">
        <div className="brand">
          <img src={ logoSimpleBlack } alt="logo" />
          <FaTrademark className="trademark"/>
        </div>
        {/* <div className="title"><h1>HydroLog</h1></div> */}
        <div className="sub-title"><p1>Make the upgrade to smart hydration.</p1></div>
        <div></div>
      <div className="color-selector-container">

      <div className="dropdown-container">
      <label htmlFor="bottle-dropdown" className="dropdown-label">
        {selectedBottle ? `Selected: ${selectedBottle}` : 'Select The Bottle You Use'}
      </label>
      <select
        id="bottle-dropdown"
        value={selectedBottle}
        onChange={handleSelect}
        className="dropdown-select"
      >
        <option value="" disabled hidden>
          Select a bottle
        </option>
        <option value="60oz Wide Mouth">HydroFlask - 60oz Wide Mouth</option>
        <option value="40oz Wide Mouth">HydroFlask - 40oz Wide Mouth</option>
        <option value="20oz Wide Mouth">HydroFlask - 20oz Wide Mouth</option>
        <option value="16oz Wide Mouth">HydroFlask - 16oz Wide Mouth</option>
      </select>
    </div>

        <div className="select-title"><p2>Choose Your HydroLog Color</p2></div>

      {/* Display the selected color name */}
      <div className="color-name">
        {selectedColor ? selectedColor.name : 'Select a color'}
        <div className="cost">49.99</div>
      </div>
      

      {/* Color Boxes */}
      <div className="color-boxes">
        {colors.map((colorItem, index) => (
          <div
            key={index}
            className={`color-box ${selectedColor?.name === colorItem.name ? 'selected' : ''}`}
            style={{ backgroundColor: colorItem.color }}
            onClick={() => handleSelectColor(colorItem)}
          />
        ))}
      </div>


      {/* Buy Button */}
          <a 
            href={selectedColor ? selectedColor.link : '#'} 
            className={`buy-button ${selectedColor ? '' : 'disabled'}`}
            target="_blank" 
              rel="noopener noreferrer"
            > Buy </a>
        </div>
        <div className="compatablity">
            <p>Works with Wide Mouth HydroFlask</p>
        </div>
        <div className="disclaimer">
          <p>* * Strap Not Included</p>
        </div>
      </div>
    </div>
  )
}
