import React from 'react'
import stayhydrated from '../assets/stayhydrated.png'
import { FaRegCopyright } from "react-icons/fa";


export default function MastHead() {
  return (
    <div className="masthead">
        <div></div>
        <div className="stay-hydrated"><img src = { stayhydrated } alt = "Stay Hydrated" />
        <FaRegCopyright style={{ fontSize: '7px', color: '#ffffff', marginBottom: '17px', marginLeft: '-3px'  }} />
        </div>
        <div></div>
    </div>
  )
}
