import React, { useState } from 'react'
import logoSimple from '../assets/logoSimple.png'

export default function Navbar() {
const [navState, setNavState] = useState(false);
  return (
    <nav>
        <div className="brand-container">
            <div className="brand">
                <img src={ logoSimple } alt="logo" />
            </div>
            <div className="toggle-container">
                <div className="toggle"></div>
                <div className="mode"></div>
            </div>
        </div>
        {/* <div className="links-container">
            <ul className="links">
                <li>
                    <a href="#">Features</a>
                </li>
                <li>
                    <a href="#">Story</a>
                </li>
                <li>
                    <a href="#">Sign Up</a>
                </li>
            </ul>
        </div> */}
    </nav>
  )
}
