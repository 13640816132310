import React from 'react';
import IphoneMock from "../assets/IphoneMock.png";
import HydroFlaskLogo from "../assets/HydroFlaskLogo.png";
import { TiChartArea } from "react-icons/ti";
import { IoBody } from "react-icons/io5";

export default function Steps() {
  return (
    <div className="steps">
      <div className="container">
        {/* Left side: Steps */}
        <div className="content">
          <div className="step">
            <div className="app-store-logo">
              <IoBody />
            </div>
            <p className="description">Water loss caused that faint feeling...</p>
          </div>
          <div className="step">
            <div className="chart-icon">
              <TiChartArea />
            </div>
            <p className="description">Prevent headaches with consistent hydration.</p>
          </div>
          <div className="step">
            <div className="hydroFlask-Icon">
              <img src={ HydroFlaskLogo } alt="Hydro Flask Logo" />
            </div>
            <p className="description">Works with any Wide Mouth HydroFlask</p>
          </div>
        </div>

        {/* Right side: iPhone Image */}
        <div className="iphone-image-container">
          <div className="iphone-image">
            <img src={ IphoneMock } alt="Hydrolog App Mockup" />
          </div>
        </div>
      </div>
    </div>
  );
}
