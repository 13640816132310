import React from 'react'
import BottomLog from "../assets/bottom.png"
import CenterLog from "../assets/top.png"
import LeftLog from "../assets/left.png"


export default function Design() {
  return (
    <div className="design">
      <h1 className="design-text">An intelligent accessory
      seamlessly designed for your favorite bottles.</h1>
    <div className="design-container">
       <div className="design-img"><img src={ LeftLog } alt="Hydrolog Cap" /></div> 
       <div className="design-img"><img src={ CenterLog } alt="Hydrolog Cap" /></div> 
       <div className="design-img"><img src={ BottomLog } alt="Hydrolog Cap" /></div> 
    </div>
    </div>
  )
}
